body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*CUSTOM CLASS STYLES*/
@media screen and (min-width: 770px) {
  .formColumn {
    height: 18.8rem;
  }
}

/*ANT DESIGN ELEMENT STYLES*/

/*Pagination Element Styles*/
.ant-pagination-options-size-changer.ant-select {
  width: auto;
  display: none !important;
}
.ant-select-arrow {
  color: #08ac9c !important;
}

/*Switch Styles*/
.ant-switch-checked {
  background-color: #08ac9c !important;
}

/*Select Styles*/
.ant-select-item-option-selected {
  background-color: white !important;
}
.ant-select-selector {
  text-align: left !important;
}

/*Table Styles*/
.usertable-grid {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}
.table-heading, .table-data {
  text-align: center;
  justify-content: center;
  padding: 8px;
  font-weight: bold !important;
}
.rowStyle {
  cursor: pointer;
}
.ant-table-cell {
  background-color: white !important;
  text-align: center !important;
}
.ant-table-thead > tr > th {
  font-weight: bold !important;
}
.ant-pagination-item-link {
  border-radius: 20px !important;
  background-color: #08ac9c !important;
  font-size: 8px !important;
}

.anticon-left, .anticon-right {
  color: white !important;
}
.ant-pagination-item{
  border-radius: 20px !important;
  background-color: white !important;
  color: black !important;
}
a {
  color: black !important;
}
.ant-pagination-item-active {
  color: white !important;
  background-color: #08ac9c !important;
}
.ant-pagination-item-active a {
  color: white !important;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

@media screen and (min-width: 984px) {
  .ant-table-container {
    overflow-x: hidden !important;
  }
}

/*Modal Styles*/
.modalTheme ant-modal-root {
  border-radius: 40px !important;
  border: 4px solid #ebebeb !important;
}

/*RANGE PICKER STYLE*/
@media(max-width: 576px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
}

/*RECHARTS STYLES*/
.recharts-cartesian-grid {
  display: none !important;
}

.recharts-cartesian-axis-tick-line {
  display: none !important;
}

